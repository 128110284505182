import React from 'react';
import { Component } from 'react';

class Tableau extends Component {
  componentDidMount() {  
    this.initViz();
  }  

  initViz() {  
    const vizUrl = 'https://public.tableau.com/views/ProfitDashboard_16629560335660/Dashboard1';
    const vizContainer = this.vizContainer;  
    const options = {
      width: 1100,
      height: 800,
    };
    let viz = new window.tableau.Viz(vizContainer, vizUrl, options); 
  }  

  render() {  
    return (  
      <div className='vizDiv' ref={(div) => { this.vizContainer = div }}></div>
    )  
  }  
} 

export default Tableau;